<template>
  <div>
    <div class="row">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          theme-card
          shadow-card
          p-5
        "
      >
        <h1>Add User</h1>
        <form @submit="saveUser" validate>
          <input-control
            type="horizontal"
            v-model="user.first"
            required
            labelClass="col-md-3 col-lg-2"
            >First Name</input-control
          >
          <input-control
            type="horizontal"
            v-model="user.last"
            required
            labelClass="col-md-3 col-lg-2"
            >Last Name</input-control
          >

          <select-control
            type="horizontal"
            v-model="user.gender"
            labelClass="col-md-3 col-lg-2"
            :options="genderOptions"
            >Gender</select-control
          >
          <input-control
            type="horizontal"
            v-model="user.email"
            control="email"
            required
            labelClass="col-md-3 col-lg-2"
            >Email</input-control
          >
          <input-control
            v-model="user.phone_number"
            required
            type="horizontal"
            labelClass="col-md-3 col-lg-2"
            >Phone Number</input-control
          >
          <input-control
            type="horizontal"
            v-model="user.username"
            required
            labelClass="col-md-3 col-lg-2"
            >User Name</input-control
          >
          <input-control
            type="horizontal"
            v-model="user.password"
            control="password"
            required
            labelClass="col-md-3 col-lg-2"
            >Password</input-control
          >
          <div class="form-group row">
            <label class="col-md-3 col-lg-2" for="input-control-birth_date"
              >Birth Date</label
            >
            <div class="col">
              <datetime
                id="input-control-birth_date"
                v-model="user.birth_date"
                placeholder=""
                zone="UTC"
                :week-start="7"
                format="yyyy-MM-dd"
                input-class="form-control"
              ></datetime>
            </div>
          </div>

          <div class="form-group row">
            <label
              for="input-control-is-manager"
              class="
                col-auto col-lg-2 col-form-label
                pointer-cursor
                d-flex
                align-items-center
              "
              >Managing Admin</label
            >
            <div class="col-auto is-manager">
              <input
                type="checkbox"
                id="input-control-is-manager"
                v-model="user.is_managing_admin"
                class="form-control ml-1 pointer-cursor"
              />
            </div>
          </div>
          <div class="form-group row align-items-center" v-if="false">
            <label class="col-lg-2 mb-0">Receive Notifications Via</label>
            <div class="d-flex col mr-2">
              <div class="custom-control custom-switch mr-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingSMS"
                  v-model="user.enable_sending_sms"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingSMS"
                  >SMS</label
                >
              </div>
              <div class="custom-control custom-switch ml-5">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sendingEmail"
                  v-model="user.enable_sending_email"
                />
                <label
                  class="custom-control-label pointer-cursor"
                  for="sendingEmail"
                  >Email</label
                >
              </div>
            </div>
          </div>
          <save classes="mt-3 save-button btn btn-theme" :saving="saving"
            >Save</save
          >
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import { Datetime } from "vue-datetime";

export default {
  name: "CreateUser",
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      genderOptions: [
        { value: "0", label: "Other" },
        { value: "1", label: "Male" },
        { value: "2", label: "Female" },
      ],
      saving: false,
      user: {
        first: null,
        last: null,
        email: null,
        password: null,
        phone_number: null,
        is_managing_admin: true,
        enable_sending_sms: false,
        enable_sending_email: false,
      },
    };
  },
  methods: {
    ...mapActions({
      getAllUsers: "security/getUsersWithRoles",
    }),
    saveUser: function (e) {
      e.preventDefault();

      let vm = this;
      this.saving = true;
      this.$http
        .post("users", this.user)
        .then((res) => {
          if (res) {
            vm.saving = false;

            Swal.fire({
              title: "User Created Successfully",
              text: "",
              icon: "success",
            });
            vm.$router.push("/admin/users");
            vm.getAllUsers();
          }
        })
        .catch((err) => {
          if (!err.accessDenied) {
            Swal.fire({
              title: "Error",
              text:
                (err.data || {}).message ||
                "An error occurred while creating the user, please try again",
              icon: "error",
            });
          }
          vm.saving = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.is-manager input {
  min-width: 25px;
  width: 25px;
}

@media (max-width: 767px) {
  .save-button {
    width: 40%;
    margin-left: 30%;
  }
}
</style>
